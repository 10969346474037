/* eslint-disable import/no-default-export */
import React from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import { Layout } from '../components/Layout';
import { INodePostSummary } from '../interfaces/blog';
import { PostList } from '../components/PostList/PostList';

interface ErrorPosts {
  data: {
    posts: {
      edges: INodePostSummary[];
    };
  };
}

const NotFoundPage = ({ data: { posts: { edges: posts } } }: ErrorPosts) => (
  <Layout>
    <Helmet title="Strona błędu | swiatubezpieczen.com" />
    <section id="error-page" className="section section--lightblue">
      <div className="wrapper">
        <h1>Nie znaleziono podanej strony</h1>
        <p>404</p>
        <section id="error-page-links" className="section section--error-page-links">
          <div className="wrapper">
            <a href="https://porowneo.pl/ubezpieczenia/samochodu/ubezpieczenie-oc">https://porowneo.pl/ubezpieczenia/samochodu/ubezpieczenie-oc</a>
          </div>
        </section>
        <section id="error-page-posts" className="section section--error-page">
          <div className="wrapper">
            <PostList posts={posts} title="Newsy ze świata ubezpieczeń" />
          </div>
        </section>
      </div>
    </section>
  </Layout>
);

export default NotFoundPage;

export const query = graphql`
    query ErrorPostsQuery {
        posts: allWordpressPost(
            sort: { fields: date, order: DESC }
            limit: 10
        ) {
            edges {
                node {
                    ...PostListFields
                }
            }
        }
    }
`;
